import { useAppDispatch, useAppSelector } from '@/app'
import { RouteBrowserMainView } from '@/features/route-browser'
import { PlanningMainView } from '@/features/planning'
import { ErrorBoundary } from './ErrorBoundary'
import {
  Alert,
  AlertTitle,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Divider,
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ListIcon from '@mui/icons-material/List'
import SettingsIcon from '@mui/icons-material/Settings'
import { LoginStateHandler } from '@/features/auth/LoginStateHandler'
import {
  useIsRestoring,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ConfigMainView, selectors as coreSelectors } from '@/features/core'
import { assertUnreachable } from '@alexandrainst/assert-unreachable'
import { useStyles } from './DesktopMainShellView.style'
import { SignInFlowWrapper } from '@/features/auth/SignInFlow'
import { PropertiesProvider } from '@/features/feature-flags/PropertiesProvider'
import {
  OpenFeatureClientProvider,
  useOpenFeatureClient,
} from '@/features/feature-flags'
import { useClient } from '@alexandrainst/plana-react-api'
import { SplashScreen } from '@/features/splash-screen'
import { actions, selectors } from '../shellSlice'

const Content = () => {
  const focus = useAppSelector(selectors.selectFocus)
  switch (focus) {
    case 'singleRoute':
    case 'planning':
      return <PlanningMainView />
    case 'routeBrowser':
      return <RouteBrowserMainView />
    case 'config':
      return <ConfigMainView />
    case 'splash':
      return <SplashScreen />
    default:
      assertUnreachable(focus)
  }
}

/* TODO: This is currently 1:1 the same as MobileMainShellView */
export const DesktopMainShellView = () => {
  const isRestoring = useIsRestoring()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const flags = useAppSelector(coreSelectors.selectFlags)
  const { reset } = useQueryErrorResetBoundary()
  const focus = useAppSelector(selectors.selectFocus)
  const dispatch = useAppDispatch()
  const planaClient = useClient()
  const globalFeatureClient = useOpenFeatureClient()
  const flagProvider = useMemo(
    () => new PropertiesProvider(planaClient, globalFeatureClient),
    [globalFeatureClient, planaClient]
  )

  useEffect(() => {
    if (flags.singleRouteMode && focus === 'planning') {
      dispatch(actions.setFocus('singleRoute'))
    }
  }, [dispatch, flags.singleRouteMode, focus])

  if (isRestoring) {
    return <SplashScreen />
  }

  return (
    <OpenFeatureClientProvider provider={flagProvider}>
      <SignInFlowWrapper>
        <ErrorBoundary
          beforeCapture={scope => {
            scope.setTag('source', 'DesktopMainShellView')
          }}
          onReset={reset}
          fallback={({ resetError, error }) => (
            <Container
              component="main"
              maxWidth="xs"
              className={classes.container}
            >
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => resetError()}
                  >
                    <Trans t={t} i18nKey="shell.buttons.tryAgain">
                      Try again
                    </Trans>
                  </Button>
                }
              >
                <AlertTitle>
                  <Trans t={t} i18nKey="common.error">
                    Error
                  </Trans>
                </AlertTitle>
                <Trans t={t} i18nKey="shell.messages.unexpectedError">
                  There was an unexpected error
                </Trans>
                <Divider sx={{ margin: '5px' }} />
                <Box>
                  {error instanceof Error
                    ? `[${error.message}]`
                    : '[Unknown error]'}
                </Box>
              </Alert>

              <LoginStateHandler />
            </Container>
          )}
        >
          <Content />
        </ErrorBoundary>

        <BottomNavigation
          showLabels
          value={focus}
          onChange={(_: unknown, newFocus) =>
            dispatch(actions.setFocus(newFocus))
          }
        >
          <BottomNavigationAction
            value="planning"
            label={t('shell.bottomNav.planning')}
            icon={<CalendarMonthIcon />}
          />
          {flags.showAllRoutes && (
            <BottomNavigationAction
              disabled
              value="routeBrowser"
              label={t('shell.bottomNav.routeBrowser')}
              icon={<ListIcon />}
            />
          )}
          <BottomNavigationAction
            value="config"
            label={t('shell.bottomNav.config')}
            icon={<SettingsIcon />}
          />
        </BottomNavigation>
      </SignInFlowWrapper>
    </OpenFeatureClientProvider>
  )
}
